import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as Herz from '@rushplay/herz'
import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Suspense from './suspense'
import { QueryDrawer } from './query-drawer'
import { ScrollLock } from './scroll-lock'

const LoginBox = loadable(() => import('./login-box'))

export function LoginDrawer() {
  const location = ReactRouter.useLocation()
  const i18n = I18n.useI18n()
  const session = Herz.Auth.useSession()
  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )

  // Login is available on the landing-page as a returning user.
  if (session.authenticated || (returningPlayer && location.pathname === '/')) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="login"
      title={i18n.translate('login-page.title')}
    >
      <Suspense.Boundary>
        <Common.Box pb={6} color="g-text">
          <ScrollLock />
          <LoginBox />
        </Common.Box>
      </Suspense.Boundary>
    </QueryDrawer>
  )
}

// for @loadable/components
export default LoginDrawer
